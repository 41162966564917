<template>
  <div class="">
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
            <div class="card">
              <div class="card-body table-responsive table-head">
                <div class="row bor-bot">
                  <div class="col-md-8 pl-0 mb-3">
                    <h1 class="m-0 text-dark pt-2 pb-2">Club Insurance</h1>
                  </div>
                  <div class="col-md-4">
                    <div class="text-right pb-3">
                      <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                        <li class="breadcrumb-item"><Breadcrumbs/></li>
                      </ol>
                    </div> 
                  </div>
                </div>

                <div class="table-responsive mt-3">
                  <div class="data-tablebutn pb-3">
                      <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-club-insurance-report>
                          <i class="fas fa-sliders-h"></i> Filters
                      </b-button>
                      <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                          <i class="fas fa-redo"></i> Reset
                      </b-button>
                      <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                          <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                      </b-button>
                  </div>
                  <table class="table table-sm table-bordered table-hover" id="list_table">
                    <thead class="thead-dark">
                    <tr>
                      <th>Club Name</th>
                      <th>Company Name</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Transaction ID</th>
                      <th>Transaction Date</th>
                      <th>Fulfilled</th>
                      <th>Fulfilled Date</th>
                    </tr>
                    </thead>
                    <tbody>
                      <!-- Data will be placed here -->
                      <tr>
                          <td colspan="19">&nbsp;</td>
                      </tr>
                      <tr>
                          <td colspan="19">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <b-modal id="modal-club-insurance-report" v-bind:hide-footer="true" ref="modal-club-insurance-report" size="lg"  title="Advanced Search">
                    <form ref="form" method="post" @submit.prevent="advancedSearch()">
                        <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label class="form-label">Club Name</label>
                                  <input type="text" id="club_name" class="form-control" v-model="searchForm.club_name">
                              </div>
                          </div>

                          <div class="col-md-6">
                              <div class="form-group">
                                  <label class="form-label">Company Name</label>
                                  <input type="text" id="company_name" class="form-control" v-model="searchForm.company_name">
                              </div>
                          </div>

                          <div class="col-md-6">
                              <div class="form-group">
                                  <label class="form-label">First Name</label>
                                  <input type="text" id="first_name" class="form-control" v-model="searchForm.first_name">
                              </div>
                          </div>

                          <div class="col-md-6">
                              <div class="form-group">
                                  <label class="form-label">Last Name</label>
                                  <input type="text" id="last_name" class="form-control" v-model="searchForm.last_name">
                              </div>
                          </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="fulfilled_start_date" class="form-label">Fulfilled From Date</label>
                                    <div class="input-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick
                                                v-model="searchForm.fulfilled_start_date"
                                                :format="'MM/DD/YYYY'"
                                                :displayFormat="'MM/DD/YYYY'"
                                                v-mask="'##/##/####'"
                                                :parseDate="parseDatePick"
                                            ></date-pick>
                                        </div>
                                    </div>
                                    <input id="fulfilled_start_date" type="hidden" class="form-control" :value="fulfilled_start_date_1">
                                    <div class="text-sm text-danger">{{ errors.fulfilled_start_date }}</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="fulfilled_end_date" class="form-label">Fulfilled To Date</label>
                                    <div class="input-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick
                                                v-model="searchForm.fulfilled_end_date"
                                                :format="'MM/DD/YYYY'"
                                                :displayFormat="'MM/DD/YYYY'"
                                                v-mask="'##/##/####'"
                                                :parseDate="parseDatePick"
                                            ></date-pick>
                                        </div>
                                    </div>
                                    <input id="fulfilled_end_date" type="hidden" class="form-control" :value="fulfilled_end_date_1">
                                    <div class="text-sm text-danger">{{ errors.fulfilled_end_date }}</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="fulfilled" class="form-label">Fulfilled / Unfulfilled</label>
                                    <select name="fullfilled" id="fullfilled" class="form-control" v-model="searchForm.fullfilled">
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label">Transaction ID</label>
                                    <input type="text" id="transaction_id" class="form-control" v-model="searchForm.transaction_id">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="transaction_start_date" class="form-label">Transaction From Date</label>
                                    <div class="input-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick
                                                v-model="searchForm.transaction_start_date"
                                                :format="'MM/DD/YYYY'"
                                                :displayFormat="'MM/DD/YYYY'"
                                                v-mask="'##/##/####'"
                                                :parseDate="parseDatePick"
                                            ></date-pick>
                                        </div>
                                    </div>
                                    <input id="transaction_start_date" type="hidden" class="form-control" :value="transaction_start_date_1">
                                    <div class="text-sm text-danger">{{ errors.transaction_start_date }}</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="transaction_end_date" class="form-label">Transaction To Date</label>
                                    <div class="input-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick
                                                v-model="searchForm.transaction_end_date"
                                                :format="'MM/DD/YYYY'"
                                                :displayFormat="'MM/DD/YYYY'"
                                                v-mask="'##/##/####'"
                                                :parseDate="parseDatePick"
                                            ></date-pick>
                                        </div>
                                    </div>
                                    <input id="transaction_end_date" type="hidden" class="form-control" :value="transaction_end_date_1">
                                    <div class="text-sm text-danger">{{ errors.transaction_end_date }}</div>
                                </div>
                            </div>
                                                  
                            <div class="col-md-12 modal-searchbtn text-center">
                                <div class="form-group">
                                    <button type="submit" class="btn btn-primary btn-blue">Search</button>
                                </div>
                                <div class="text-sm text-danger">{{ errors.not_found }}</div>
                            </div>
                        </div>
                    </form>
                </b-modal>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from 'jquery';
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from 'moment';
import DatePick from "vue-date-pick";
import axios from 'axios';

const validateName = name => {
    if (name.length || name != "") {
        return { valid: true, error: null };
    }
    return { valid: false, error: null };
};

export default {
  components: {  },
  name: "ClubInsurance",
  data(){
    return {
      exportProgress: false,
      filtersApplied: false,
      searchForm: {
        fulfilled_start_date: '',
        fulfilled_end_date: '',
        fullfilled: '',
        club_name: '',
        company_name: '',
        first_name: '',
        last_name: '',
        transaction_id: '',
        transaction_start_date: '',
        transaction_end_date: '',
      },
      table: {}
    }
  },
  methods: {
    refreshList(event){
      this.searchForm = {
        fulfilled_start_date: '',
        fulfilled_end_date: '',
        fullfilled: '',
        club_name: '',
        company_name: '',
        first_name: '',
        last_name: '',
        transaction_id: '',
        transaction_start_date: '',
        transaction_end_date: '',
      };
      // this.table.clear().draw();
      this.table.ajax.reload();
      this.filtersApplied = false;
    },
    advancedSearch() {
        if(!this.validate()) {
            return;
        }
        // Trigger submit handler
        this.table.draw();
        this.filtersApplied = true;
        this.$refs['modal-club-insurance-report'].hide();
    },
    validate() {
      this.errors = {}
      this.valid =  true;

      var dateValid = false;

      const validFulfilledStartDate = validateName(this.searchForm.fulfilled_start_date);
      this.errors.fulfilled_start_date = validFulfilledStartDate.error;

      const validFulfilledEndDate = validateName(this.searchForm.fulfilled_end_date);
      this.errors.fulfilled_end_date = validFulfilledEndDate.error;

      if(validFulfilledStartDate.valid && !this.validateDate(this.searchForm.fulfilled_start_date)){
        dateValid = true;
        this.errors.fulfilled_start_date = "Invalid Date";
      }
      if(validFulfilledEndDate.valid && !this.validateDate(this.searchForm.fulfilled_end_date)){
        dateValid = true;
        this.errors.fulfilled_end_date = "Invalid Date";
      }

      if(dateValid == false){
        if((validFulfilledStartDate.valid && validFulfilledEndDate.valid) && (moment(this.searchForm.fulfilled_end_date).isBefore(this.searchForm.fulfilled_start_date))) {
            this.errors.fulfilled_end_date = 'End date must be greater than Start date!';
            return this.valid =  false;
        } else {
            this.valid = true;
        }
      } else {
          this.valid = false;
      }

      const validTStartDate = validateName(this.searchForm.transaction_start_date);
      this.errors.transaction_start_date = validTStartDate.error;

      const validTEndDate = validateName(this.searchForm.transaction_end_date);
      this.errors.transaction_end_date = validTEndDate.error;
      
      dateValid = false;
      if(validTStartDate.valid && !this.validateDate(this.searchForm.transaction_start_date)){
          dateValid = true;
          this.errors.transaction_start_date = "Invalid Date";
      }
      if(validTEndDate.valid && !this.validateDate(this.searchForm.transaction_end_date)){
          dateValid = true;
          this.errors.transaction_end_date = "Invalid Date";
      }

      if(dateValid == false){
          if((validTStartDate.valid && validTEndDate.valid) && (moment(this.searchForm.transaction_end_date).isBefore(this.searchForm.transaction_start_date))) {
              this.errors.transaction_end_date = 'End date must be greater than Start date!';
              return this.valid =  false;
          } else {
              this.valid = true;
          }
      } else {
          this.valid = false;
      }

      return this.valid;
    },
    exportCsv(){
        var self = this;
        $.ajax({
            url: process.env.VUE_APP_URL + "api/reports/export/club-insurance",
            type: 'post',
            "data": this.table.ajax.params(),
            beforeSend: function(){
                self.exportProgress = true;
            },
            "success": function(res, status, xhr) {
                self.exportProgress = false;
                if(res.file_url != undefined) window.open(res.file_url, '_blank');
            },
            error: function(err){
                console.log(err);
                self.exportProgress = false;
            }
        });
    }
  },
  mounted() {
    var self = this;
    this.table = $("#list_table").DataTable({
        dom: "lfrtip",
        processing: true,
        serverSide: true,
        bFilter: true,
        responsive: true,
        ajax: {
            url: process.env.VUE_APP_URL + "api/report/club_insurance",
            type: 'post',
            data: function (d) {
              d.team_name = $("#club_name").val() || self.searchForm.club_name;
              d.team_insurance_company_name = $("#company_name").val() || self.searchForm.company_name;
              d.team_insurance_first_name = $("#first_name").val() || self.searchForm.first_name;
              d.team_insurance_last_name = $("#last_name").val() || self.searchForm.last_name;
              d.fulfilled_start_date = $("#fulfilled_start_date").val() || self.searchForm.fulfilled_start_date;
              d.fulfilled_end_date = $("#fulfilled_end_date").val() || self.searchForm.fulfilled_end_date;
              d.fulfilled_club_insurance = $("#fullfilled").val() || self.searchForm.fullfilled;
              d.team_insurance_transaction_id = $("#transaction_id").val() || self.searchForm.transaction_id;
              d.team_insurance_transaction_start_date = $("#transaction_start_date").val() || self.searchForm.transaction_start_date;
              d.team_insurance_transaction_end_date = $("#transaction_end_date").val() || self.searchForm.transaction_end_date;
            }
        },
        columns: [
          { data: 'club_name', name: 'club_name', orderable: true, searchable: true },
          { data: 'company_name', name: 'company_name', orderable: true, searchable: true },
          { data: 'first_name', name: 'first_name', orderable: true, searchable: true },
          { data: "last_name", name: "last_name", orderable: true, searchable: true  },
          { data: "start_date", name: "start_date", orderable: true, render: (data) => { return data ? moment(data).format("MM/DD/YYYY") : "N/A"; } },
          { data: "end_date", name: "end_date", orderable: true, render: (data) => { return data ? moment(data).format("MM/DD/YYYY") : "N/A"; } },
          { data: "transaction_code", name: "transaction_code", orderable: true, searchable: true  },
          { data: "transaction_time", name: "transaction_time", orderable: true, searchable: true, render: (data) => { return data ? moment(data).format("MM/DD/YYYY") : "N/A"; } },
          { data: "fulfilled_club_insurance", name: "fulfilled_club_insurance", orderable: false, searchable: false },
          { data: "fulfilled_date", name: "fulfilled_date", orderable: false, searchable: false },
        ],
      order: [[5, "desc"]]
    });

    let headers = {headers: this.adminHeaders};
    let url = this.basePath + "api/update_club_insurance";
    document.addEventListener("change", function(e){
      console.clear();
      if(e.target.className=='fulfilledChk') {
        const checked = e.target;
        let updateFulfilledData={
          id:e.target.dataset.id,
          fulfilled: (checked.checked?1:0)
        };
        axios.post( url, updateFulfilledData, headers)
            .then(
                function(response) {
                  this.table.draw();
                }.bind(this)
            ).catch(function(error) {
          console.log(error);
        });
      }
    })
  },
  computed: {
      fulfilled_start_date_1: function () {
          return this.searchForm.fulfilled_start_date;
      },
      fulfilled_end_date_1: function(){
          return this.searchForm.fulfilled_end_date;
      },
      transaction_start_date_1: function () {
          return this.searchForm.transaction_start_date;
      },
      transaction_end_date_1: function(){
          return this.searchForm.transaction_end_date;
      },
  }
}

</script>

<style scoped>

</style>